import { useEffect, useState } from "react";
import HelperService from "../../../Services/HelperService";
import "./WMTable.css";
import { Dropdown, Form, Table } from "react-bootstrap";
import { PiDotsThreeCircleVertical } from "react-icons/pi";
import WebMaxyPagination from "../Pagination/WebMaxyPagination";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import NoDataFound from "../../../assets/images/empty_item.svg";
import TableLoader from "../TableLoader/TableLoader";
import NoImage from "../../../assets/images/noImage.png";
import { useTranslation } from "react-i18next";
import { TooltipCustom } from "../Tooltip/Tooltip";
import { BsFillPlayCircleFill } from "react-icons/bs";
import { TbReportAnalytics } from "react-icons/tb";
interface PropData {
  list: any[];
  headers: any[];
  totalCount: number;
  getData: any;
  getAction: any;
  loader: boolean;
  action_condtion?: any;
  onClick?: any;
  getCurrentPage?: any;
  customClassName?: string
  no_record_message?: string
  currentPage?: number
}

const WMTable = (props: PropData) => {
  const { t } = useTranslation();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentColum, setcurrentColum] = useState("");
  const [currentSort, setcurrentSort] = useState("DESC");
  const [ref, setRefObj] = useState({});
  const setRef = (ref: any, type: string) => {
    document.getElementById("wbTable")?.classList.remove("table-no-scroll");
    setRefObj(ref);
    var obj = {
      type: type,
      ref: ref,
    };
    props.getAction(obj);
  };


  useEffect(() => {
    setCurrentPage(props.currentPage ?? 1)
  }, [props.currentPage])
  const sorting = (header: any) => {
    var shotBy = header.sort_by;
    var cl = header.colum_name;
    if (header.colum_prefix && header.colum_prefix !== "") {
      cl = header.colum_prefix + "." + header.colum_name;
    }
    if (currentColum !== cl) {
      shotBy = "DESC";
    } else {
      if (currentSort === "DESC") {
        shotBy = "ASC";
      } else {
        shotBy = "DESC";
      }
    }
    props.headers.forEach((element) => {
      element.is_default_sort = false;
      element.sort_by = "";
    });
    header.is_default_sort = true;
    header.sort_by = shotBy;
    setcurrentSort(shotBy);
    if (header.colum_prefix && header.colum_prefix !== "") {
      setcurrentColum(header.colum_prefix + "." + header.colum_name);
    } else {
      setcurrentColum(header.colum_name);
    }
  };

  useEffect(() => {
    var body = {
      currentPage: currentPage,
      currentColum: currentColum,
      currentSort: currentSort,
    };
    props.getData(body);
  }, [currentPage, currentColum, currentSort]);

  const clickTd = (item: any, header: any) => {
    if (props.onClick) {
      props.onClick(item, header);
    }
  };
  const checkConditon = (item: any, action: any, header: any) => {
    if (props.action_condtion) {
      var flag = props.action_condtion(item, action);
      if (flag) {
        header.show = flag;
      }
      return flag;
    }
  };
  function getTd(header: any, index: any, header_index: any) {
    var item = props.list[index];
    return header.clickable ? (
      <td
        onClick={() => clickTd(item, header)}
        key={"WMTable_" + index + "_" + header_index}
        className={
          header.type !== "NUMBER"
            ? "text-nowrap mClass colorText "
            : header?.align === "center" ? "text-nowrap mClass colorText" : "text-nowrap mClass colorText numberAlign"
        }
      >
        {getInnerHtml(header, index, header_index)}
      </td>
    ) :
      <td
        key={"WMTable_" + index + "_" + header_index}
        className={
          header.type !== "NUMBER"
            ? "text-nowrap  "
            : header?.align === "center" ? "text-nowrap" : "text-nowrap numberAlign"
        }
      >
        {getInnerHtml(header, index, header_index)}
      </td>


  }

  const actionClicked = () => {
    document.getElementById("wbTable")?.classList.add("table-no-scroll");
  };

  const setRef2 = (event: any, item: any) => {
    var obj = {
      type: "CHECKBOX",
      ref: item,
      checked: event.target.checked,
    };
    props.getAction(obj);
  };

  const setRef3 = (event: any, item: any) => {
    var obj = {
      type: "SWITCH",
      ref: item,
      checked: event.target.checked,
    };
    props.getAction(obj);
  };

  function getInnerHtml(header: any, index: any, header_index: any) {
    return header.type === "DATE" ? (
      HelperService.getFormatedDateForDetail((props.list[index][header.colum_name]), (header?.format ?? ""))
    ) : header.type === "STRING" ? (
      <>
        <span>
          <TooltipCustom st={
            <span>{HelperService.truncate(props.list[index][header.colum_name], 25)}
            </span>
          }
            message={props.list[index][header.colum_name]}
            position="auto"
            id={props.list[index] + "1"} /></span></>
    ) : header.type === "CHECKBOX" ? (
      <input
        type="checkbox" id={"checkid" + props.list[index]} name={"checkid" + props.list[index]} checked={props.list[index].selected}
        onChange={(event: any) => setRef2(event, props.list[index])}
      />
    ) : header.type === "SWITCH" ? (
      <Form.Check
        type="switch"
        id={"custom-switch" + props.list[index]}
        label=" "
        className="cursor-pointer font-18"
        checked={props.list[index].switched}
        onChange={(event: any) => setRef3(event, props.list[index])}
      />
    ) : header.type === "NUMBER" ? (

      <span><TooltipCustom st={
        <span>{HelperService.getFormatedNumber(
          HelperService.abbrNum(props.list[index][header.colum_name], 2),
          header.prefix,
          header.suffix
        )}
        </span>
      }
        message={HelperService.getFormatedNumber(HelperService.numberWithCommas(Number(props.list[index][header.colum_name])), header.prefix,
          header.suffix
        )}
        position="top"
        id={index + "23123"} /></span>

    ) : header.type === "EMAIL" ? (
      props.list[index][header.colum_name]
    ) : header.type === "STATUS" ? (
      <span
        className={
          props.list[index]["status_class"]
            ? props.list[index]["status_class"]
            : ""
        }
      >

        {
          (props.list[index][header.colum_name] === "REJECTED" || props.list[index][header.colum_name] === "Failed") ?

            <TooltipCustom st={
              <span>{HelperService.truncate(HelperService.getFormatedStatusText(
                props.list[index][header.colum_name]
              ), 25)}
              </span>
            }
              message={props.list[index].rejected_reason ? props.list[index].rejected_reason : (props.list[index].error_message ? props.list[index].error_message : (props.list[index].failed_reason ? props.list[index].failed_reason : ""))}
              position="top"
              id={props.list[index] + "1"} />
            :
            props.list[index][header.colum_name] === "COD" ?
              props.list[index][header.colum_name]
              :
              HelperService.getFormatedStatusText(
                props.list[index][header.colum_name]
              )
        }
      </span>
    ) : header.type === "IMAGE" ? (
      <img
        alt=""
        height={"25px"}
        className={header.class ? header.class : ""}
        src={props.list[index][header.colum_name]}
      />
    ) : header.type === "IMAGE_WITH_TEXT" ? (
      <>
        <img alt="" onError={(error: any) => (error.target.src = NoImage)}
          className={header.class ? header.class : ""}
          src={props.list[index][header.image_colum]}
        />{" "}
        <>{props.list[index][header.colum_name]}</>{" "}
      </>
    )
      : header.type === "INFO" ? (
        <>
          {props.list[index][header.colum_name]}
        </>
      )
      : header.type === "BUTTON" ? (
        <>
          {props.list[index][header.colum_name] ? 
          <span>
            <TooltipCustom 
              st={
                <span>{header?.sub_type === "VIEW" ? <TbReportAnalytics size={22} /> : <BsFillPlayCircleFill size={22} />}</span>
              }
              message={props.list[index][header.colum_name]}
              position="auto"
              id={props.list[index] + "1"} 
            />
          </span>  : ""}
        </>
      )
        : header.type === "ACTION" ? (
          <Dropdown className={header.show ? "action-dd" : "action-dd "} onClick={actionClicked}>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <PiDotsThreeCircleVertical size={18} className="icon" />
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {header.actions?.map((action: any, action_index: any) => {
                return ((action.title === 'Test Template' && props.list[index].status === 'REJECTED') || (action.title === 'Edit' && props.list[index].is_past === true)) ? (
                  <></>
                ) : (
                  <div key={"WMTable_dropdown" + action_index + "_" + index}>
                    {
                      <Dropdown.Item
                        onClick={() => setRef(props.list[index], action.type)}
                        style={{
                          display: checkConditon(props.list[index], action, header)
                            ? "block"
                            : "none",
                        }}
                      >
                        {action.title}
                      </Dropdown.Item>
                    }
                  </div>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        ) : (
          props.list[index][header.colum_name]
        );
  }

  const getHeader = () => {
    return (
      <tr>
        {props.headers?.map((header: any, index: any) => {
          return header.is_sorting ? (
            header.is_default_sort ? (
              <th
                key={"WMTable_a" + index}
                className={
                  header.type !== "NUMBER"
                    ? "mClass text-nowrap "
                    : header?.align === "center" ? "mClass text-nowrap" :  "mClass text-nowrap numberAlign"
                }
                onClick={() => sorting(header)}
              >
                {header.display_name}{" "}
                {header.sort_by === "DESC" ? (
                  <FiChevronDown size={14} />
                ) : (
                  <FiChevronUp size={14} />
                )}
              </th>
            ) : (
              <th
                key={"WMTable_a" + index}
                className={
                  header.type !== "NUMBER"
                    ? "mClass text-nowrap "
                    : header?.align === "center" ? "mClass text-nowrap" : "mClass text-nowrap numberAlign"
                }
                onClick={() => sorting(header)}
              >
                {header.display_name}{" "}
              </th>
            )
          ) : header.type === "ACTION" ? (
            <th
              key={"WMTable_a" + index}
              className="text-nowrap action-col"
            >
              {header.display_name}
            </th>
          ) : (
            <th
              key={"WMTable_a" + index}
              className={
                header.type !== "NUMBER"
                  ? " text-nowrap "
                  : header?.align === "center" ? "text-nowrap" : " text-nowrap numberAlign"
              }
            >
              {header.display_name}
            </th>
          );
        })}
      </tr>
    )
  }
  return (
    <>
      <div
        className={props.customClassName !== "" ? "table-responsive mb-3" : "table-wrap  table-responsive mb-3  table-no-scroll"}
        id="wbTable"
      >
        {!props.loader ? (
          <>

            {props.list.length > 0 ? (
              <Table hover className="wmx-table-style mb-0 ">
                <thead>
                  {getHeader()}
                </thead>
                <tbody>
                  {props.list?.map((item: any, index: any) => {
                    return (
                      <tr key={"WMTable_tr" + index}>
                        {props.headers?.map(
                          (header: any, header_index: any) => {
                            return getTd(header, index, header_index);
                          }
                        )}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            ) : (
              <div className="table-wrap position-relative">
                <div className="no-data-flound">
                  <div className="text-center">
                    <img alt="" src={NoDataFound} width={110} className="mb-3" />
                    <h3 className="text-secondary font-12">{props.no_record_message ? props.no_record_message : t('Common.No_Record_Found')}</h3>
                  </div>
                </div>
              </div>
            )}
          </>)
          :
          (
            <TableLoader />
          )

        }
      </div>
      {
        props.totalCount > 10 ?
          <div className='table-pagination mt-2'>
            <WebMaxyPagination
              showLoader={props.loader}
              className=" "
              changePage={(page: number) => { setCurrentPage(page); props.getCurrentPage && props.getCurrentPage(page); }}
              totalItems={props.totalCount}
              changeCurrentPage={currentPage}
            />
          </div >
          : ""
      }
    </>
  );
};

export default WMTable;
